@import 'styles/_theme.scss';

.button {
  display: inline-block;
  margin: 0;
  padding: 0.8125em 1em;
  box-sizing: border-box;

  border: 1px solid;
  cursor: pointer;
  transition: $transition;

  text-align: center;
  text-decoration: none;
  font-size: $font-size-base;
  font-weight: 500;
  color: $color-white;

  &:focus,
  &:active {
    box-shadow: 0 0 0 3px hsla(212, 72%, 55%, 0.2);
  }

  &.--full-width {
    display: block;
    width: 100%;
  }

  &.--color-accent {
    background: $color-accent;
    border-color: $color-accent;

    &:hover {
      color: $color-accent;
    }
  }

  &.--color-primary {
    background: $color-primary;
    border-color: $color-primary;

    &:hover {
      color: $color-primary;
    }
  }

  &.--color-secondary {
    background: $color-secondary;
    border-color: $color-secondary;

    &:hover {
      color: $color-secondary;
    }
  }

  &.--color-transparent-dark,
  &.--color-transparent-light {
    background: transparent;
  }

  &.--color-transparent-dark {
    border-color: transparentize($color-secondary-light, 0.6);

    &:hover {
      color: $color-primary;
    }
  }

  &.--color-transparent-light {
    border-color: transparentize($color-primary, 0.3);

    color: $color-primary;

    &:hover {
      background: $color-primary;
      color: $color-white;
    }
  }

  &:hover {
    background: $color-white;
  }
}
