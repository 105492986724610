.TermsOfUse {
  width: 750px;
  margin: auto;
  padding-bottom: 300px;
  color: black;

  h1 {
    text-align: center;
  }
  
  span {
    font-size: 19px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}