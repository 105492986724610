@import 'styles/_theme.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $color-primary;
  font-size: $font-size-xl;
  font-weight: 600;
  color: $color-white;
  padding: 12px 24px;
}

.content {
  padding: 24px;

  & p:first-child {
    margin-top: 0;
  }
}

.link {
  color: $color-secondary;
}

.btn {
  border: none;
  background: transparent;
  color: white;
  font-size: $font-size-xxl;
  padding: 0 !important;
  line-height: $font-size-xxl;
}
