.privacyPolicy {
  width: 750px;
  margin: auto;
  padding-bottom: 300px;
  color: black;

  h1 {
    text-align: center;
  }

  a {
    margin-left: 5px;
  }
}