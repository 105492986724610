$color-white: #fff;
$color-polar: #f3faff;
$color-accent: #e69624;
$color-primary: hsl(224, 33%, 40%);
$color-primary-light: hsl(224, 33%, 60%);
$color-primary-dark: hsl(224, 33%, 30%);
$color-secondary: hsl(212, 72%, 55%);
$color-secondary-light: hsl(212, 72%, 80%);
$color-secondary-dark: hsl(212, 72%, 40%);

$font-size-base: 1em;
$font-size-ratio: 1.2;

$border-radius: 3px;
$border-radius-accent: 20px;

$font-size-xs: $font-size-base / $font-size-ratio / $font-size-ratio;
$font-size-sm: $font-size-base / $font-size-ratio / $font-size-ratio;
$font-size-md: $font-size-base / $font-size-ratio;
$font-size-lg: $font-size-base;
$font-size-xl: $font-size-base * $font-size-ratio;
$font-size-xxl: $font-size-base * $font-size-ratio * $font-size-ratio;
$font-size-xxxl: $font-size-base * $font-size-ratio * $font-size-ratio;

$transition: 0.25s ease;
