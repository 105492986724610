@import 'styles/_theme.scss';

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 50px;

    background: $color-primary;
  }
}

.logo {
  padding: 15px 20px;
  margin-top: -20px;
}

.logo-image {
  width: 160px;
  height: 75px;
}

.main {
  margin: 2.5rem 0;

  &.--sizeNormal {
    width: 23.4375rem;
  }

  &.--sizeLarge {
    width: 33.125rem;
  }
}
