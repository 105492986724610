@import 'styles/_theme.scss';

.not-found-box {
  display: flex;
  flex-direction: column;
  height: 350px;
  padding-top: 1em;

  text-align: center;
  font-size: 1.70em;
}

.heading {
  margin: 0;

  font-size: $font-size-xxl;
  font-family: serif;
}

.heading-error {
  font-size: 0.55em;
  font-weight: 500;
  line-height: 0.1em;
  margin: 0.8em 0;
}

.message {
  font-size: 0.59em;
  font-weight: 600;
  margin-top: 0.5em;
  font-family: serif;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  font-size: $font-size-sm;
}

.action {
  display: inline-flex !important;
  align-items: center;
  margin-left: 15px !important;
  padding: 0.6em 2em !important;
  font-size: 0.8em !important;
  &:first-child{
    padding-right: 4em !important;
  }

  & > svg {
    margin-right: 10px;
    path {
      transition: 0.3s;
    }
  }

  &:hover {
    svg path {
      fill: #e69624;
    }
  }

  &:first-child {
    margin-left: 0 !important;
  }
}
