@import 'styles/_theme.scss';

.footer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  padding: 0.5rem 1.25rem;
  box-sizing: border-box;
  z-index: 100;

  font-size: $font-size-sm;
  color: $color-white;

  background: $color-primary;

  &-button {
    margin-left: 0.9em !important;
    
    padding: 0.45em 0.9em !important;
  }

  &-policy {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;

    & > span {
      margin: 0 11px;
    }

    & > a {
      color: white;
      font-size: 12px;
      font-weight: 700;
    }
  }
}
