@import 'styles/_theme.scss';

.privacyPolicyDialog {
  font-size: $font-size-md;

  &_header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  
    background: $color-primary;
  
    text-align: center;
    font-size: $font-size-xl;
    font-weight: 600;
    color: $color-white;
  }

  &_body {
    padding: 1.875em;
    background: #f3faff;
  }

  &_confirm {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    p {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0;
    }
    a {
      color: $color-primary;
      font-weight: 500;
    }
    span {
      margin: 0 7px;
    }
  }

  &_buttons {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-bottom: 13px;

    button {
      width: 200px;
      margin: 0 10px;
    }
  }
}

