@font-face {
  font-family: 'Cheng & Tsui Icons';
  src: url('./icons.ttf') format('truetype'), url('./icons.woff') format('woff'),
    url('./icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: 'Cheng & Tsui Icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.--close::before {
    content: '\e900';
  }

  &.--menuDown::before {
    content: '\e901';
  }

  &.--menuUp::before {
    content: '\e902';
  }

  &.--chevronDown::before {
    content: '\e903';
  }
}
