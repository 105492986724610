@import 'styles/_theme.scss';

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  100% {
    opacity: 0;
  }
}

@keyframes dialog-fade-in {
  0% {
    margin-top: -50px;
    transform: scale(0.9);
  }
}

@keyframes dialog-fade-out {
  100% {
    margin-top: -50px;
    transform: scale(0.9);
  }
}

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  overflow: hidden auto;
  width: 100%;
  height: 100%;
  z-index: 999;

  background: rgba(0, 0, 0, 0.4);
  animation-duration: 0.3s;
  animation-name: fade-in;

  font-size: 1rem;

  &.--fade-out {
    animation-name: fade-out;
  }
}

.spacing {
  flex-shrink: 0;
  margin: auto;
  padding: 20px;
}

.dialog {
  width: 640px;

  border-radius: 2px;
  overflow: hidden;
  background: $color-polar;
  animation-duration: 0.3s;
  animation-name: dialog-fade-in;

  &.--fade-out {
    animation-name: dialog-fade-out;
  }
}
