@import 'styles/_theme.scss';

html,
input,
textarea,
keygen,
select,
button {
  font-family: 'Roboto', sans-serif;
}

*:focus {
  outline: 2.5px solid rgba(15, 173, 221, 0.692);
  outline-offset: 2px;
}

body {
  background-color: $color-polar;
  margin: 0;

  color: $color-primary;
}

@keyframes loaderFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes loaderFadeOut {
  100% {
    opacity: 0;
  }
}

@keyframes loaderRotate {
  100% {
    transform: rotate(360deg);
  }
}

$loader-circle-size: 50px;
$loader-ball-size: $loader-circle-size / 7;
$loader-fade-duration: 0.2s;

.loaderWrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  background: rgba(255, 255, 255, 0.6);

  &--fadeIn {
    animation: loaderFadeIn $loader-fade-duration;
  }

  &--fadeOut {
    animation: loaderFadeOut $loader-fade-duration;
  }
}

.loader {
  position: relative;
  animation: loaderRotate 0.6s 0s infinite linear;
  animation-fill-mode: 'forwards';
}

.loader-circle {
  width: $loader-circle-size;
  height: $loader-circle-size;
  border-radius: 100%;

  border: $loader-ball-size solid $color-primary;
  opacity: 0.1;
}

.loader-ball {
  position: absolute;
  top: $loader-circle-size / 2 - $loader-ball-size / 2;
  width: $loader-ball-size;
  height: $loader-ball-size;
  border-radius: 100%;

  animation: loaderRotate 0.6s 0s infinite;
  animation-fill-mode: 'forwards';

  background: $color-primary;
  opacity: 0.8;
}
